<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" id="take-picture_svg__Groupe_920" viewBox="0 0 51 51"><defs/><g id="take-picture_svg__Groupe_839" transform="translate(10 13)"><g id="take-picture_svg__Groupe_838"><path id="take-picture_svg__Tracé_631" d="M1442.326 2405.183h-29.148a1.229 1.229 0 01-1.228-1.228v-18.078a1.229 1.229 0 011.228-1.228h29.148a1.229 1.229 0 011.228 1.228v18.078a1.229 1.229 0 01-1.228 1.228zm-29.019-1.356h28.89v-17.82h-28.89z" transform="translate(-1411.951 -2380.54)"/><path id="take-picture_svg__Tracé_632" d="M1474.023 2359.819a.678.678 0 01-.586-1.019l2.253-3.873a1.207 1.207 0 011-.575h8.258a1.207 1.207 0 011 .575l2.253 3.872a.678.678 0 11-1.172.682l-2.2-3.773h-8.027l-2.2 3.773a.678.678 0 01-.579.338zm10.751-4.21zm-7.911 0z" transform="translate(-1465.018 -2354.352)"/><path id="take-picture_svg__Tracé_633" d="M1442.584 2370.8h-2.32a.678.678 0 010-1.357h2.32a.678.678 0 010 1.357z" transform="translate(-1435.837 -2367.4)"/><path id="take-picture_svg__Tracé_634" d="M1481.435 2420.7a7.377 7.377 0 117.377-7.377 7.385 7.385 0 01-7.377 7.377zm0-13.4a6.02 6.02 0 106.02 6.02 6.027 6.027 0 00-6.02-6.012z" transform="translate(-1465.633 -2398.952)"/><path id="take-picture_svg__Tracé_635" d="M1491.715 2428.683a.678.678 0 01-.678-.678 5.079 5.079 0 015.074-5.074.678.678 0 110 1.357 3.721 3.721 0 00-3.717 3.717.678.678 0 01-.679.678z" transform="translate(-1480.309 -2413.628)"/><path id="take-picture_svg__Tracé_636" d="M1591.692 2410.556a2.3 2.3 0 112.3-2.3 2.305 2.305 0 01-2.3 2.3zm0-3.248a.946.946 0 10.946.946.947.947 0 00-.946-.946z" transform="translate(-1565.321 -2398.952)"/></g></g></svg>
</template>

<script>
  export default {
    name : 'MeepIconTakePicture'
  };
</script>
