<template>
  <section class="get-picture">
    <img
      v-if="fileToUpload"
      class="get-picture-preview"
      :src="fileToUpload"
      alt=""
    />
    <div class="get-picture-webcam" v-if="isWebcamOn">
      <vue-web-cam ref="webcam" :selectFirstDevice="true" />
      <div class="get-picture-webcam-button">
        <md-button class="md-primary" @click="onStop"
          >Désactiver le camera</md-button
        >
        <md-button class="md-primary" @click="onCapture"
          >Prendre un photo</md-button
        >
      </div>
    </div>

    <div
      class="get-picture-container"
      @dragover.prevent="dragOver"
      @dragleave.prevent="dragLeave"
      @drop.prevent="drop($event)"
    >
      <div class="get-picture-in-progress" v-if="isDragging">En cours</div>
      <template v-else>
        <div class="get-picture-file">
          <label for="files">
            <MeepIconGetPicture class="app-icon-inverted" />
          </label>

          <input
            id="files"
            type="file"
            name="files"
            onclick="this.value = null;"
            accept="image/*"
            @change="onFileAdded"
          />
        </div>
        <md-button @click="openWebCam" :md-ripple="false">
          <MeepIconTakePicture class="app-icon-inverted" />
        </md-button>
        <md-button v-if="fileToUpload" @click="onClear" :md-ripple="false">
          <MeepIconDeletePicture class="app-icon-inverted" />
        </md-button>
      </template>
    </div>
  </section>
</template>

<script>
import MeepIconGetPicture from "./icons/MeepIconGetPicture";
import MeepIconDeletePicture from "./icons/MeepIconDeletePicture";
import MeepIconTakePicture from "./icons/MeepIconTakePicture";
import { WebCam } from "vue-web-cam";
import { createBlobFromBinary } from "@/services/util";
export default {
  name: "GetPicture",
  components: {
    MeepIconGetPicture,
    MeepIconTakePicture,
    MeepIconDeletePicture,
    "vue-web-cam": WebCam,
  },
  props: {
    nameGenerator: {
      type: Function,
      required: true,
    },
  },
  mounted() {},
  data() {
    return {
      fileToUpload: null,
      isDragging: false,
      isWebcamOn: false,
      file: null,
    };
  },
  methods: {
    dragOver() {
      this.isDragging = true;
    },

    dragLeave() {
      this.isDragging = false;
    },

    drop(e) {
      if (!e.dataTransfer.files.length) {
        return;
      }
      let file = e.dataTransfer.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        this.fileToUpload = event.target.result;
      };
      reader.readAsDataURL(file);
      this.file = file;
      this.changeFileName();
      this.isDragging = false;

      //this.form.logo = logo;
    },

    onCapture() {
      this.fileToUpload = this.$refs.webcam.capture();
      this.file = createBlobFromBinary(this.fileToUpload, "image/jpeg");
      this.changeFileName();
    },

    changeFileName() {
      const fileName = this.nameGenerator();
      this.$emit("change", fileName);
    },

    onFileAdded(e) {
      if (!e.target.files.length) {
        return;
      }
      let file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        this.fileToUpload = event.target.result;
        this.file = file;
      };
      this.changeFileName();

      reader.readAsDataURL(file);
    },

    openWebCam() {
      this.isWebcamOn = true;
    },

    onStop() {
      this.isWebcamOn = false;
    },

    onClear() {
      this.file = null;
      this.fileToUpload = null;
      this.$emit("change", null);
    },
  },
  computed: {},
};
</script>

<style lang="scss">
@import "../styles/_variable.scss";
.get-picture {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 15px;

  &-preview {
    margin: 20px;
  }

  &-container {
    display: flex;
    width: 100%;
    height: 81px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #f2f5f7;
    border-radius: 10px;
    gap: 15px;
    margin-top: 20px;
    @include for-lg {
      height: 122px;
    }
    .md-button {
      width: 34px;
      height: 34px;
      background: var(--bg-primary);
      border-radius: 11px;
      min-width: unset;
      min-height: unset;
      @include for-lg {
        width: 51px;
        height: 51px;
      }
      &-content {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      svg {
        width: 40px;
        height: 40px;
        @include for-lg{
          width: 51px;
          height: 51px;

        }
      }
    }
  }

  &-file {
    padding: 0;
    display: flex;
    justify-content: flex-end;

    label {
      position: relative;
      cursor: pointer;
      background: var(--bg-primary);
      border-radius: 11px;
      height: 34px;
      width: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include for-lg {
        height: 51px;
        width: 51px;
      }
      &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        opacity: 0;
        transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
        will-change: background-color, opacity;
        border-radius: 11px;
        content: " ";
      }

      &:hover::before {
        background-color: currentColor;
        opacity: 0.12;
      }

      .app-icon {
        width: 45px;
        height: 45px;
      }
    }

    input {
      display: none;
    }
  }

  &-webcam {
    margin-top: 20px;

    &-button {
      display: flex;
      justify-content: space-around;
      margin-top: 15px;
    }
  }

  &-in-progress {
    font-size: 20px;
    font-family: var(--font-bold);
  }
}
</style>
