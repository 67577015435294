import store from "../store";
import fetch from "../services/fetch";

const getAllEmployee = companyId => {
  let queryParams = "";

  if (companyId) {
    queryParams += `?company_id=${companyId}`;
  }
  return fetch("api/employees" + queryParams, {
    method: "get",
    headers: { Authorization: store.state.token },
  });
};

const createEmployee = payload => {
  return fetch("api/employees", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};

const getEmployee = employeeId => {
  return fetch(`api/employees/${employeeId}`, {
    method: "get",
    headers: { Authorization: store.state.token },
  });
};

const updateEmployee = (companyId, employeeId, payload) => {
  return fetch(`api/employees/${employeeId}?company_id=${companyId}`, {
    method: "PUT",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};

const deleteEmployee = employeeId => {
  return fetch(`api/employees/${employeeId}`, {
    method: "DELETE",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
  });
};

export default {
  getAllEmployee,
  createEmployee,
  getEmployee,
  updateEmployee,
  deleteEmployee,
};
